//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { DownloadComponent } from "./components/download/download.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/signin/signin.component";
import { StringConstant } from "./constants/string.constant";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { PublicGuardService } from "./services/guards/public.guard.service";

//#endregion


//#region DECLARATION

const arrayRoutes: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "home",
		redirectTo: "home/dashboard/utilitydashboard",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "dashboard",
				loadChildren: () => import("./components/dashboards/dashboard.module").then(pageModule => pageModule.DashboardModule)
			},
			{
				path: "ematerai",
				loadChildren: () => import("./components/ematerai/ematerai.module").then(pageModule => pageModule.EmateraiModule)
			},
			{
				path: "corporateperformance",
				loadChildren: () => import("./components/corporateperformance/corporateperformance.module").then(pageModule => pageModule.CorporateperformanceModule)
			},
			{
				path: "totalasset",
				loadChildren: () => import("./components/totalasset/totalasset.module").then(pageModule => pageModule.TotalassetModule)
			},
			{
				path: "totalcapitalexpenditures",
				loadChildren: () => import("./components/capitalexpenditures/capitalexpenditure.module").then(pageModule => pageModule.CapitaExpendituresModule)
			},
			{
				path: "riskbasedcapital",
				loadChildren: () => import("./components/riskbasedcapitals/riskbasedcapital.module").then(pageModule => pageModule.RiskbasedcapitalModule)
			},
			{
				path: "industry",
				loadChildren: () => import("./components/industries/industry.module").then(pageModule => pageModule.IndustryModule)
			},
			{
				path: "about",
				component: AboutComponent,
			},
			{
				path: "ticket",
				loadChildren: () => import("./components/ticket/ticket.module").then(pageModule => pageModule.TicketModule)
			},
			{
				path: "reimbursement",
				children:
				[
					{
						path: "approval",
						// eslint-disable-next-line @typescript-eslint/no-unsafe-return
						loadChildren: () => import("./components/approvalreimbursement/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
				]
			},
			{
				path: "financialhighlightworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/financialhighlights/financialhighlightworkplanbudget.module").then(pageModule => pageModule.FinancialhighlightWorkplanBudgetModule)
			},
			{
				path: "capitalexpenditureworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/capitalexpenditures/capitalexpenditureworkplanbudget.module").then(pageModule => pageModule.CapitalExpenditureWorkPlanBudgetModule)
			},
			{
				path: "operationexpenditureworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/operationexpenditures/operationexpenditureworkplanbudget.module").then(pageModule => pageModule.OperationExpenditureWorkPlanBudgetModule)
			},
			{
				path: "supervisionbranchworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/supervisionbranchs/supervisionbranchworkplanbudget.module").then(pageModule => pageModule.SupervisionBranchworkplanbudgetModule)
			},
			{
				path: "performancesegmentworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/performancesegments/performancesegmentworkplanbudget.module").then(pageModule => pageModule.PerfomanceSegmentworkplanbudgetModule)
			},
			{
				path: "feebasedincomeworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/feebasedincomes/feebasedincomeworkplanbudget.module").then(pageModule => pageModule.FeeBasedIncomeWorkPlanBudgetModule)
			},
			{
				path: "segmentationportfoliopillarworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/segmentationportfoliopillars/segmentationportfoliopillarworkplanbudget.module").then(pageModule => pageModule.SegmentationPortfolioPillarWorkPlanBudgetModule)
			},
			{
				path: "pillarportfoliosegmentworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/pillarportfoliosegments/pillarportfoliosegmentworkplanbudget.module").then(pageModule => pageModule.PillarPortfolioSegmentworkplanbudgetModule)
			},
			{
				path: "digitalportfoliosegmentworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/digitalportfoliosegments/digitalportfoliosegmentworkplanbudget.module").then(pageModule => pageModule.DigitalPortfolioSegmentworkplanbudgetModule)
			},
			{
				path: "captiveportfoliosegmentworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/captiveportfoliosegments/captiveportfoliosegmentworkplanbudget.module").then(pageModule => pageModule.CaptivePortfolioSegmentworkplanbudgetModule)
			},
			{
				path: "syariahportfoliosegmentworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/syariahportfoliosegments/syariahportfoliosegmentworkplanbudget.module").then(pageModule => pageModule.SyariahPortfolioSegmentworkplanbudgetModule)
			},
			{
				path: "investmentworkplanbudget",
				loadChildren: () => import("./components/workplanbudget/investments/investmentworkplanbudget.module").then(pageModule => pageModule.InvestmentWorkplanBudgetModule)
			},
			{
				path: "uncommitedcreditlineplafond",
				loadChildren: () => import("./components/uncommitedcreditlineplafonds/uncommitedcreditlineplafond.module").then(pageModule => pageModule.UncommitedCreditLinePlafondModule)
			},
			{
				path: "rcsa",
				children:
				[
					{
						path: "assessment",
						loadChildren: () => import("./components/rcsa/assessment/assessment.module").then(pageModule => pageModule.AssessmentModule)
					},
					{
						path: "approval",
						loadChildren: () => import("./components/rcsa/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
					{
						path: "report",
						loadChildren: () => import("./components/rcsa/report/report.module").then(pageModule => pageModule.ReportModule)
					},
					{
						path: "riskissuerequest",
						loadChildren: () => import("./components/rcsa/riskissuerequest/riskissuerequest.module").then(pageModule => pageModule.RiskissuerequestModule)
					},
				],
			},
			{
				path: "gcgonline",
				loadChildren: () => import("./components/gcgonline/gcgonline.module").then(pageModule => pageModule.GcgOnlineModule)
			},
			{
				path: "digitalization",
				loadChildren: () => import("./components/digitalization/submissions/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "brinseditorial",
				loadChildren: () => import("./components/brinseditorial/brinseditorial.module").then(pageModule => pageModule.BrinsEditorialModule)
			},
			{
				path: "apu-ppt",
				loadChildren: () => import("./components/apu-ppt/apu-ppt.module").then(pageModule => pageModule.APUPPTModule)
			},
			{
				path: "brinspro",
				loadChildren: () => import("./components/brinspro/brinspro.module").then(pageModule => pageModule.BrinsproModule)
			}
		]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoutes,
				{
					preloadingStrategy: QuicklinkStrategy
				})
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
