<div id="divBodyHome" appCheckDevTools>
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelStrategicAndPlanningSignIn.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelStrategicAndPlanningSignIn.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">{{ _modelStrategicAndPlanningSignIn.getDivisionName() }}</span>
			</figcaption>

			<!-- <a class="Underline" (click)="callSignOut();">Profile</a> |  -->
			<a class="Underline SignOut" (click)="callSignOut();">Sign out</a>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerDashboard">Dashboard</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerDashboard" [checked]="this._booleanNavigationItemDashboard" [(ngModel)]="_booleanNavigationItemDashboard">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToUtilityDashboard();">Utility</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToDigitalDashboard();">Digital</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerPerformace">Corporate Performance</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPerformace" [checked]="this._booleanNavigationItem3" [(ngModel)]="_booleanNavigationItem3">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToFinancialHighlight();">Financial Highlight</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToRatioHighlight();">Ratio Highlight</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToCapitalExpenditure();">Capital Expenditure</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToOperationExpenditure();">Operation Expenditure</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToOutstandingPremi();">Outstanding Premi</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToInvestation();">Investasi</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProductionPerCOB();">Produksi Per COB</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProductionGroupUnit();">Produksi Per Uker</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProductionFeeBasedIncome();">FBI & UCL</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProductionPerSegment();">Produksi Per Segmen</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTotalSumInsured();">TSI Aktif</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToMatrixSegment();">Matriks Segmentasi</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerTicket">Ticket</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTicket" [checked]="this._booleanNavigationItem" [(ngModel)]="_booleanNavigationItem">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReceived();">Received</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSent();">Create</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReport();">Report Ticket</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSatisfactionReport();">Report  Satisfaction Ticket</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationEmeterei">e-Meterai</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationEmeterei" [checked]="this._booleanNavigationEmaterai" [(ngModel)]="_booleanNavigationEmaterai">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToEmateraiForMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToEmateraiForChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToEmateraiForSigner();">Signer</a>
					</li>
				</ul>
			</li> -->
			<!-- <ng-container *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerReimbursement">Reimbursement</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerReimbursement" [checked]="this._booleanNavigationReimbursement" [(ngModel)]="_booleanNavigationReimbursement">
					<ul class="ListSubNavigation" style="font-size: 15px;">
						<li class="ListItemNavigation">
							<label for="checkBoxNavigationTriggerApproval">Approval</label>
							<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApproval" [checked]="this._booleanNavigationApproval" [(ngModel)]="_booleanNavigationApproval">
							<ul class="ListSubNavigation">
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
									<a (click)="goToReimbursementGeneralChecker();">Checker</a>
								</li>
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
									<a (click)="goToReimbursementGeneralSigner();">Signer</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ng-container> -->
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
				<label for="checkBoxNavigationTriggerRcsa">RCSA</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRcsa" [checked]="this._booleanNavigationItemRCSA" [(ngModel)]="_booleanNavigationItemRCSA">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToAssessment();">Assessment</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER);">
						<a (click)="goToApprovalRCSA();">Approval</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToReportRCSA();">Report</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRiskIssueRequest();">Risk Issue Request</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerGCGOnline">GCG Online</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerGCGOnline" [checked]="this._booleanNavigationItemGCGOnline" [(ngModel)]="_booleanNavigationItemGCGOnline">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="!_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGADMIN);">
						<a (click)="goToGCGOnlineProfile();">Profile</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineGratification();">Gratification</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineIntegrityPact();">Integrity Pact</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerCodeOfEthics">Code of Ethics</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerCodeOfEthics" [checked]="this._booleanNavigationCodeOfEthics" [(ngModel)]="_booleanNavigationCodeOfEthics">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineCodeOfEthics();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineCodeOfEthicsApproval();">Approval</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerAnnualDisclosure">Annual Disclosure</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAnnualDisclosure" [checked]="this._booleanNavigationAnnualDisclosure" [(ngModel)]="_booleanNavigationAnnualDisclosure">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineAnnualDisclosure();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineAnnualDisclosureApproval();">Approval</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerDeclaration">Legal Document Request</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerDeclaration" [checked]="this._booleanNavigationLegal" [(ngModel)]="_booleanNavigationLegal">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="!_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer) && !_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToDigitalizationSigner();">Signer</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToBRINSEditorial();">BRINS Editorial</a>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerApuPPT">APU PPT</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApuPPT" [checked]="this._booleanNavigationApuPPT" [(ngModel)]="_booleanNavigationApuPPT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAPUPPTCustomer();">Customer Data</a>
					</li>
				</ul>
			</li> -->

			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerBrinsprime">BRINSPRIME</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerBrinsprime" [checked]="this._booleanNavigationBrinsprime" [(ngModel)]="_booleanNavigationBrinsprime">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerKeyPerformanceBranch">KPI Kantor Cabang</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerKeyPerformanceBranch" [checked]="this._booleanNavigationKeyPerformanceBranch" [(ngModel)]="_booleanNavigationKeyPerformanceBranch">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeCPS)">
								<a (click)="goToMasterKeyPerformanceList()">Master Data</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeCPS) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeMaker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeSigner)">
								<a (click)="goToKeyPerformanceMonitoring()">Reporting</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerKeyPerformanceCompany">KPI Perusahaan</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerKeyPerformanceCompany" [checked]="this._booleanNavigationKeyPerformanceCompany" [(ngModel)]="_booleanNavigationKeyPerformanceCompany">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeCPS)">
								<a (click)="goToMasterKeyPerformanceCorporateList()">Master Data</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeMaker)">
								<label for="checkBoxNavigationTriggerKeyPerformanceCorporateSubmission">Submission</label>
								<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerKeyPerformanceCorporateSubmission" [checked]="_booleanNavigationKeyPerformanceCorporateSubmission" [(ngModel)]="_booleanNavigationKeyPerformanceCorporateSubmission">
								<ul class="ListSubNavigation">
									<li class="ListItemSubNavigation">
										<a (click)="goToKeyPerformanceCorporateSubmission()">Submission KPI</a>
									</li>
									<li class="ListItemSubNavigation">
										<a (click)="goToKeyPerformanceCorporateSubmited()">Submit List KPI</a>
									</li>
								</ul>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeSigner)">
								<a (click)="goToKeyPerformanceApprovalCompany()">Approval</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeSigner) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeMaker)">
								<a (click)="goToKeyPerformanceCorporateReporting()">Reporting</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerKeyPerformanceDirector">KPI Direksi</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerKeyPerformanceDirector" [checked]="this._booleanNavigationKeyPerformanceDirector" [(ngModel)]="_booleanNavigationKeyPerformanceDirector">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToMasterKeyPerformanceDirector()">Master Data</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeMaker)">
								<label for="checkBoxNavigationTriggerKeyPerformanceDirectorSubmission">Submission</label>
								<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerKeyPerformanceDirectorSubmission" [checked]="_booleanNavigationKeyPerformanceDirectorSubmission" [(ngModel)]="_booleanNavigationKeyPerformanceDirectorSubmission">
								<ul class="ListSubNavigation">
									<li class="ListItemSubNavigation">
										<a (click)="goToKeyPerformanceDirectorSubmission()">Submission KPI</a>
									</li>
									<li class="ListItemSubNavigation">
										<a (click)="goToKeyPerformanceDirectorSubmited()">Submit List KPI</a>
									</li>
								</ul>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeSigner)">
								<a (click)="goToKeyPerformanceDirectorApproval()">Approval</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeSigner) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupAccess.BrinsPrimeHeadOfficeMaker)">
								<a (click)="goToKeyPerformanceDirectorReporting()">Reporting</a>
							</li>
						</ul>
					</li>
				</ul>
			</li> -->

			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerBrinspro">BRINSPRO</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerBrinspro" [checked]="_booleanNavigationBrinspro" [(ngModel)]="_booleanNavigationBrinspro">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goProjectManagementDashboard()">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProjectManagementProject()">Project</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProjectManagementDocument()">Document</a>
					</li>
				</ul>
			</li>

			<li class="ListItemNavigation">
				<a (click)="goToAbout();">About</a>
			</li>
		</ul>

		<div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
			<div>
				<span>RENSTRA</span>
				<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Avoid any hassle to return this app and gives you better experience!</p>
				<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">To install this web app on your device tap the 'Share' button and then 'Add to Home Screen' button.</p>
			</div>

			<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
		</div>

		<a href="https://www.ssltrust.com.au/security-report?domain=renstra.web.brinesia.app" rel="nofollow" target="new"><img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a>

		<iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0" id="iframeSignIn"></iframe>
	</nav>

	<main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
		<router-outlet></router-outlet>
	</main>

	<input type="button" name="buttonNavigation" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" [ngClass]="{'DisplayNone': this._booleanNavigation}" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">
</div>