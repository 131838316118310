//#region IMPORT

import { ENUM_USERGROUPID } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class UserGroupAccessModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for UserGroupAccessModel model, used for User Model.
		Author: Andri Septiawan
		Created on : Tuesday, 19 April 2020.     		    Updated on : Tuesday, 19 April 2022
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	 */
	ID?: number;
	UserID?: number;
	UserGroupID?: ENUM_USERGROUPID;

	/* Attribute - END */
}

//#endregion