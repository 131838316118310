//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_MARITAL_STATUS, ENUM_REGISTER_TYPE, ENUM_RELIGION_TYPE, ENUM_RESPONSE_STATE, ENUM_SALUTATION_TYPE, ENUM_LETTER_ROLE, ENUM_USERGROUPID } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { HandshakeModel } from "./handshake.model";
import { ResponseModel } from "./response.model";
import { StrategicAndPlanningModel } from "./strategicandplanning.model";
import { LetterRoleModel } from "./letterrole.model";
import { BranchModel } from "./branch.model";
import { DivisionModel } from "./division.model";
import { UserGroupAccessModel } from "./usergroupaccess.model";

//#endregion


//#region CLASS

export class UserModel extends BaseModel
{
	//#region ATTRIBUTE

	ID?: number;
	Salutation?: ENUM_SALUTATION_TYPE;
	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	FullName?: string;
	BirthDate?: Date;
	stringBirthDate?: string;
	BirthPlace?: string;
	Gender?: ENUM_GENDER_TYPE;
	MaritalStatus?: ENUM_MARITAL_STATUS;
	Religion?: ENUM_RELIGION_TYPE;
	NationalityID?: number;

	Address?: string;
	CountryID?: number;
	ProvinceID?: number;
	CityID?: number;
	SubDistrictID?: number;
	VillageID?: number;
	HamletCode?: string;
	NeighbourhoodCode?: string;
	PostalID?: number;
	Latitude?: number;
	Longitude?: number;

	Telephone?: string;
	Fax?: string;
	MobilePhone?: string;
	MobilePhoneNew?: string;
	MobilePhoneToken?: string;
	Email?: string;
	EmailNew?: string;
	EmailToken?: string;
	URLValidation?: string;

	IdentificationNumber?: string;
	IdentificationType?: ENUM_IDENTIFICATION_TYPE;
	IdentificationName?: string;
	IdentificationURL?: string;
	Password?: string;
	PasswordNew?: string;
	PasswordConfirmation?: string;
	DeviceID?: string;
	Key?: string;

	UserLevelID?: number;
	UserPreferenceID?: number;
	LastLoginOn?: Date;
	FailedAttemptLogin?: number;
	SignUpType?: ENUM_REGISTER_TYPE;
	SignUpID?: string;
	SignUpToken?: string;
	Signnumberype?: ENUM_REGISTER_TYPE;

	modelHandshake?: HandshakeModel;
	modelDivision?: DivisionModel;
	modelBranch?: BranchModel;
	modelStrategicAndPlanning?: StrategicAndPlanningModel;
	Culture?: string;
	Token?: string;

	search?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.modelDivision = new DivisionModel();
		this.modelBranch = new BranchModel();
		this.setModelDivison();
		this.setModelBranch();
	}

	//#endregion


	//#region SETTER

	setModelDivison(): void
	{
		const stringModelDivision: string = JSON.stringify(this.modelDivision);
		this.modelDivision = new DivisionModel();
		this.modelDivision.setModelFromString(stringModelDivision);
	}

	setModelBranch(): void
	{
		const stringModelBranch: string = JSON.stringify(this.modelBranch);
		this.modelBranch = new BranchModel();
		this.modelBranch.setModelFromString(stringModelBranch);
	}

	//#endregion


	//#region GETTER

	getName(): string
	{
		if (this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			let stringName: string = this.FirstName;

			if (this.MiddleName == null || this.MiddleName === undefined || this.MiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.MiddleName;
			}

			if (this.LastName == null || this.LastName === undefined || this.LastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.LastName;
			}

			return stringName;
		}
	}

	getNameInitial(): string
	{
		const arrayName: Array<string> = this.getName().split(StringConstant.STRING_CHARACTER_DASH);

		if (arrayName != null && arrayName !== undefined)
		{
			if (arrayName.length >= 2)
			{
				return arrayName[0].substring(0, 1) + arrayName[arrayName.length - 1].substring(0, 1);
			}
			else
			{
				return arrayName[0].substring(0, 2);
			}
		}
		else
		{
			return "";
		}
	}

	//#endregion


	//#region VALIDATION

	validateEmail(): ResponseModel
 	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAILWITHOUTSPACE);

		if (this.Email == null || this.Email === undefined || this.Email === "" || this.Email.replace(StringConstant.STRING_FIELD_EMAIL_BRINS, "") === "undefined" || this.Email.replace(StringConstant.STRING_FIELD_EMAIL_WORK_BRINS, "") === "undefined")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.Email))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	validateEmailToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.EmailToken == null || this.EmailToken === undefined || this.EmailToken === "")
		{
			modelResponse.MessageContent = "Token Email can't be empty.";
		}
		else if (this.EmailToken.length !== RuleConstant.NUMBER_EMAILTOKEN_LENGTH)
		{
			modelResponse.MessageContent = "Token Email should be " + RuleConstant.NUMBER_EMAILTOKEN_LENGTH + " characters length.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	//endregion


	//#region CHECK

	checkLetterRole(enumLetterRole: ENUM_LETTER_ROLE): boolean
	{
		if (this.listModelLetterRole != null && this.listModelLetterRole !== undefined)
		{
			if (this.listModelLetterRole.length > 0)
			{
				const modelLetterRoleResult: LetterRoleModel | undefined = this.listModelLetterRole.find(function(modelLetterRoleSearch: LetterRoleModel)
				{
					return modelLetterRoleSearch.Role === enumLetterRole;
				});

				return modelLetterRoleResult !== undefined;

				// return true;
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	checkGroupAccessRCSA(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.RiskChampion ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.RiskApprover);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_RISKCHAMPION)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.RiskChampion)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_RISKAPPROVER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.RiskApprover)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessGCGRole(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.GCGAdmin ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.GCGChecker ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.GCGSigner);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_GCGADMIN)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.GCGAdmin)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_GCGCHECKER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.GCGChecker)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_GCGSIGNER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.GCGSigner)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}


	checkGroupAccessBrinsprime(enumUserGroupID: ENUM_USERGROUPID): boolean
	{
		let modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> =
			this.listModelUserGroupAccess.filter(
				modelUserGroupAccessFilter =>
					modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsPrimeHeadOfficeMaker ||
					modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsPrimeHeadOfficeChecker ||
					modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsPrimeHeadOfficeSigner ||
					modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsPrimeCPS

			);

			if(arrayModelUserGroupAccessTemp)
			{
				modelUserGroupAccess = arrayModelUserGroupAccessTemp.filter(modelUserGroupAccessLoop => modelUserGroupAccessLoop.UserGroupID === enumUserGroupID)[0];

				if(modelUserGroupAccess)
				{
					return true;
				}
				else
				{
					return false;
				}
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	checkGroupAccessBrinspro(enumUserGroupID: ENUM_USERGROUPID): boolean
	{
		let modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> =
			this.listModelUserGroupAccess.filter(
				modelUserGroupAccessFilter =>
					modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsproChecker ||
					modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsproMaker ||
					modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsproSigner

			);

			if(arrayModelUserGroupAccessTemp)
			{
				modelUserGroupAccess = arrayModelUserGroupAccessTemp.filter(modelUserGroupAccessLoop => modelUserGroupAccessLoop.UserGroupID === enumUserGroupID)[0];

				if(modelUserGroupAccess)
				{
					return true;
				}
				else
				{
					return false;
				}
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
		this.modelBranch = undefined;
		this.modelBranchFormation = undefined;
		this.modelDivision = undefined;
		this.modelEducation = undefined;
		this.modelEmployeePointClaim = undefined;
		this.modelEmployeeStatus = undefined;
		this.modelEmployeeStepSummaryActivity = undefined;
		this.modelFormation = undefined;
		this.modelGrade = undefined;
		this.modelHandshake = undefined;
		this.modelStrategicAndPlanning = undefined;
		this.modelMutationType = undefined;
		this.modelPosition = undefined;
		this.modelSubFormation = undefined;
		this.modelUserGallery = undefined;
		this.modelUserSigner = undefined;
	}

	cleanList(): void
	{
		this.listModelEducation = undefined;
		this.listModelEmployeePerformance = undefined;
		this.listModelFamily = undefined;
		this.listModelLetterRole = undefined;
		this.listModelPositionHistory = undefined;
		this.listModelUserGallery = undefined;
		this.listModelUserGroupAccess = undefined;
	}


	//#endregion
}

//#endregion