//#region IMPORT

import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxEchartsModule } from "ngx-echarts";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { ApprovalReimbursementListComponent } from "./components/approvalreimbursement/approvalreimbursementlist/approvalreimbursementlist.component";
import { DentalreimbursementdetailComponent } from "./components/approvalreimbursement/dentalreimbursementdetail/dentalreimbursementdetail.component";
import { MaternityreimbursementdetailComponent } from "./components/approvalreimbursement/maternityreimbursementdetail/maternityreimbursementdetail.component";
import { OpticreimbursementdetailComponent } from "./components/approvalreimbursement/opticreimbursementdetail/opticreimbursementdetail.component";
import { PostmaternityreimbursementdetailComponent } from "./components/approvalreimbursement/postmaternityreimbursementdetail/postmaternityreimbursementdetail.component";
import { PrematernityreimbursementdetailComponent } from "./components/approvalreimbursement/prematernityreimbursementdetail/prematernityreimbursementdetail.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { YearMonthFormatDirective } from "./components/bases/baseformatyear.directive";
import { BrinsEditorialDetailComponent } from "./components/brinseditorial/brinseditorialdetail/brinseditorialdetail.component";
import { BrinsEditorialListComponent } from "./components/brinseditorial/brinseditoriallist/brinseditoriallist.component";
import { CapitalexpendituredetailComponent } from "./components/capitalexpenditures/capitalexpendituredetail/capitalexpendituredetail.component";
import { CapitalexpenditurelistComponent } from "./components/capitalexpenditures/capitalexpenditurelist/capitalexpenditurelist.component";
import { CapitalexpenditureComponent } from "./components/corporateperformance/capitalexpenditure/capitalexpenditure.component";
import { FeebasedincomeComponent } from "./components/corporateperformance/feebasedincome/feebasedincome.component";
import { FinancialhighlightComponent } from "./components/corporateperformance/financialhighlight/financialhighlight.component";
import { InvestationComponent } from "./components/corporateperformance/investation/investation.component";
import { MatrixsegmentationComponent } from "./components/corporateperformance/matrixsegmentation/matrixsegmentation.component";
import { OperationexpenditureComponent } from "./components/corporateperformance/operationexpenditure/operationexpenditure.component";
import { OutstandingpremiComponent } from "./components/corporateperformance/outstandingpremi/outstandingpremi.component";
import { ProductiongroupunitComponent } from "./components/corporateperformance/productiongroupunit/productiongroupunit.component";
import { ProductionpercobComponent } from "./components/corporateperformance/productionpercob/productionpercob.component";
import { ProductionpersegmentComponent } from "./components/corporateperformance/productionpersegment/productionpersegment.component";
import { RatiohighlightComponent } from "./components/corporateperformance/ratiohighlight/ratiohighlight.component";
import { TotalsuminsuredComponent } from "./components/corporateperformance/totalsuminsured/totalsuminsured.component";
import { DashboardComponent } from "./components/dashboards/dashboard/dashboard.component";
import { DigitaldashboardComponent } from "./components/dashboards/digitaldashboard/digitaldashboard.component";
import { SubmissionDetailComponent } from "./components/digitalization/submissions/submissiondetail/submissiondetail.component";
import { SubmissionInsertComponent } from "./components/digitalization/submissions/submissioninsert/submissioninsert.component";
import { SubmissionListComponent } from "./components/digitalization/submissions/submissionlist/submissionlist.component";
import { DownloadComponent } from "./components/download/download.component";
import { EmateraicheckersignerdetailComponent } from "./components/ematerai/emateraicheckersignerdetail/emateraicheckersignerdetail.component";
import { EmateraicheckersignerlistComponent } from "./components/ematerai/emateraicheckersignerlist/emateraicheckersignerlist.component";
import { EmateraimakerdetailComponent } from "./components/ematerai/emateraimakerdetail/emateraimakerdetail.component";
import { EmateraimakerlistComponent } from "./components/ematerai/emateraimakerlist/emateraimakerlist.component";
import { AnnualdisclosurecheckersignerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerdetail/annualdisclosurecheckersignerdetail.component";
import { AnnualdisclosurecheckersignerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerlist/annualdisclosurecheckersignerlist.component";
import { AnnualdisclosuredetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuredetail/annualdisclosuredetail.component";
import { AnnualdisclosureinsertComponent } from "./components/gcgonline/annualdisclosure/annualdisclosureinsert/annualdisclosureinsert.component";
import { AnnualdisclosurelistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurelist/annualdisclosurelist.component";
import { CodeofethicscheckersignerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerdetail/codeofethicscheckersignerdetail.component";
import { CodeofethicscheckersignerlistComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerlist/codeofethicscheckersignerlist.component";
import { CodeofethicsdetailComponent } from "./components/gcgonline/codeofethics/codeofethicsdetail/codeofethicsdetail.component";
import { CodeofethicsinsertComponent } from "./components/gcgonline/codeofethics/codeofethicsinsert/codeofethicsinsert.component";
import { CodeofethicslistComponent } from "./components/gcgonline/codeofethics/codeofethicslist/codeofethicslist.component";
import { GratificationdetailComponent } from "./components/gcgonline/gratification/gratificationdetail/gratificationdetail.component";
import { GratificationlistComponent } from "./components/gcgonline/gratification/gratificationlist/gratificationlist.component";
import { IntegritypactdetailComponent } from "./components/gcgonline/integritypact/integritypactdetail/integritypactdetail.component";
import { IntegritypactinsertComponent } from "./components/gcgonline/integritypact/integritypactinsert/integritypactinsert.component";
import { IntegritypactlistComponent } from "./components/gcgonline/integritypact/integritypactlist/integritypactlist.component";
import { ProfilegcgonlineComponent } from "./components/gcgonline/profilegcgonline/profilegcgonline.component";
import { HomeComponent } from "./components/home/home.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { IndustrydetailComponent } from "./components/industries/industrydetail/industrydetail.component";
import { IndustrylistComponent } from "./components/industries/industrylist/industrylist.component";
import { ApprovalrcsadetailComponent } from "./components/rcsa/approval/approvalrcsadetail/approvalrcsadetail.component";
import { ApprovalrcsalistComponent } from "./components/rcsa/approval/approvalrcsalist/approvalrcsalist.component";
import { AssessmentdetailComponent } from "./components/rcsa/assessment/assessmentdetail/assessmentdetail.component";
import { AssessmentlistComponent } from "./components/rcsa/assessment/assessmentlist/assessmentlist.component";
import { ReportdetailComponent } from "./components/rcsa/report/reportdetail/reportdetail.component";
import { ReportrcsalistComponent } from "./components/rcsa/report/reportrcsalist/reportrcsalist.component";
import { RiskissuerequestdetailComponent } from "./components/rcsa/riskissuerequest/riskissuerequestdetail/riskissuerequestdetail.component";
import { RiskissuerequestlistComponent } from "./components/rcsa/riskissuerequest/riskissuerequestlist/riskissuerequestlist.component";
import { RiskbasedcapitaldetailComponent } from "./components/riskbasedcapitals/riskbasedcapitaldetail/riskbasedcapitaldetail.component";
import { RiskbasedcapitallistComponent } from "./components/riskbasedcapitals/riskbasedcapitallist/riskbasedcapitallist.component";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { TicketdetailComponent } from "./components/ticket/ticketdetail/ticketdetail.component";
import { TicketlistinboxComponent } from "./components/ticket/ticketlistinbox/ticketlistinbox.component";
import { TicketlistoutboxComponent } from "./components/ticket/ticketlistoutbox/ticketlistoutbox.component";
import { TicketReportComponent } from "./components/ticket/ticketreport/ticketreport/ticketreport.component";
import { TicketReportSatisfactionComponent } from "./components/ticket/ticketreport/ticketreportsatisfaction/ticketreportsatisfaction.component";
import { TicketSatisfactionComponent } from "./components/ticket/ticketsatisfaction/ticketsatisfaction.component";
import { TotalassetdetailComponent } from "./components/totalasset/totalassetdetail/totalassetdetail.component";
import { TotalassetlistComponent } from "./components/totalasset/totalassetlist/totalassetlist.component";
import { UncommitedcreditlineplafonddetailComponent } from "./components/uncommitedcreditlineplafonds/uncommitedcreditlineplafonddetail/uncommitedcreditlineplafonddetail.component";
import { UncommitedcreditlineplafondlistComponent } from "./components/uncommitedcreditlineplafonds/uncommitedcreditlineplafondlist/uncommitedcreditlineplafondlist.component";
import { CapitalexpendituresworkplanbudgetdetailComponent } from "./components/workplanbudget/capitalexpenditures/capitalexpendituresworkplanbudgetdetail/capitalexpendituresworkplanbudgetdetail.component";
import { CapitalexpendituresworkplanbudgetlistComponent } from "./components/workplanbudget/capitalexpenditures/capitalexpendituresworkplanbudgetlist/capitalexpendituresworkplanbudgetlist.component";
import { CaptiveportfoliosegmentworkplanbudgetdetailComponent } from "./components/workplanbudget/captiveportfoliosegments/captiveportfoliosegmentworkplanbudgetdetail/captiveportfoliosegmentworkplanbudgetdetail.component";
import { CaptiveportfoliosegmentworkplanbudgetlistComponent } from "./components/workplanbudget/captiveportfoliosegments/captiveportfoliosegmentworkplanbudgetlist/captiveportfoliosegmentworkplanbudgetlist.component";
import { DigitalportfoliosegmentworkplanbudgetdetailComponent } from "./components/workplanbudget/digitalportfoliosegments/digitalportfoliosegmentworkplanbudgetdetail/digitalportfoliosegmentworkplanbudgetdetail.component";
import { DigitalportfoliosegmentworkplanbudgetlistComponent } from "./components/workplanbudget/digitalportfoliosegments/digitalportfoliosegmentworkplanbudgetlist/digitalportfoliosegmentworkplanbudgetlist.component";
import { FeebasedincomeworkplanbudgetdetailComponent } from "./components/workplanbudget/feebasedincomes/feebasedincomeworkplanbudgetdetail/feebasedincomeworkplanbudgetdetail.component";
import { FeebasedincomeworkplanbudgetlistComponent } from "./components/workplanbudget/feebasedincomes/feebasedincomeworkplanbudgetlist/feebasedincomeworkplanbudgetlist.component";
import { FinancialhighlightdetailComponent } from "./components/workplanbudget/financialhighlights/financialhighlightdetail/financialhighlightdetail.component";
import { FinancialhighlightlistComponent } from "./components/workplanbudget/financialhighlights/financialhighlightlist/financialhighlightlist.component";
import { InvestmentworkplanbudgetdetailComponent } from "./components/workplanbudget/investments/investmentworkplanbudgetdetail/investmentworkplanbudgetdetail.component";
import { InvestmentworkplanbudgetlistComponent } from "./components/workplanbudget/investments/investmentworkplanbudgetlist/investmentworkplanbudgetlist.component";
import { OperationexpenditureworkplanbudgetdetailComponent } from "./components/workplanbudget/operationexpenditures/operationexpenditureworkplanbudgetdetail/operationexpenditureworkplanbudgetdetail.component";
import { OperationexpenditureworkplanbudgetlistComponent } from "./components/workplanbudget/operationexpenditures/operationexpenditureworkplanbudgetlist/operationexpenditureworkplanbudgetlist.component";
import { PerformancesegmentworkplanbudgetdetailComponent } from "./components/workplanbudget/performancesegments/performancesegmentworkplanbudgetdetail/performancesegmentworkplanbudgetdetail.component";
import { PerformancesegmentworkplanbudgetlistComponent } from "./components/workplanbudget/performancesegments/performancesegmentworkplanbudgetlist/performancesegmentworkplanbudgetlist.component";
import { PillarportfoliosegmentworkplanbudgetdetailComponent } from "./components/workplanbudget/pillarportfoliosegments/pillarportfoliosegmentworkplanbudgetdetail/pillarportfoliosegmentworkplanbudgetdetail.component";
import { PillarportfoliosegmentworkplanbudgetlistComponent } from "./components/workplanbudget/pillarportfoliosegments/pillarportfoliosegmentworkplanbudgetlist/pillarportfoliosegmentworkplanbudgetlist.component";
import { SegmentationportfoliopillarworkplanbudgetdetailComponent } from "./components/workplanbudget/segmentationportfoliopillars/segmentationportfoliopillarworkplanbudgetdetail/segmentationportfoliopillarworkplanbudgetdetail.component";
import { SegmentationportfoliopillarworkplanbudgetlistComponent } from "./components/workplanbudget/segmentationportfoliopillars/segmentationportfoliopillarworkplanbudgetlist/segmentationportfoliopillarworkplanbudgetlist.component";
import { SupervisionbranchworkplanbudgetdetailComponent } from "./components/workplanbudget/supervisionbranchs/supervisionbranchworkplanbudgetdetail/supervisionbranchworkplanbudgetdetail.component";
import { SupervisionbranchworkplanbudgetlistComponent } from "./components/workplanbudget/supervisionbranchs/supervisionbranchworkplanbudgetlist/supervisionbranchworkplanbudgetlist.component";
import { SyariahportfoliosegmentworkplanbudgetdetailComponent } from "./components/workplanbudget/syariahportfoliosegments/syariahportfoliosegmentworkplanbudgetdetail/syariahportfoliosegmentworkplanbudgetdetail.component";
import { SyariahportfoliosegmentworkplanbudgetlistComponent } from "./components/workplanbudget/syariahportfoliosegments/syariahportfoliosegmentworkplanbudgetlist/syariahportfoliosegmentworkplanbudgetlist.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { PwaService } from "./services/pwa.service";
import { CustomerdataComponent } from "./components/apu-ppt/customerdata/customerdata.component";
import { ProjectmanagementdetailComponent } from "./components/brinspro/projectmanagement/projectmanagementdetail/projectmanagementdetail.component";
import { ProjectmanagementinsertComponent } from "./components/brinspro/projectmanagement/projectmanagementinsert/projectmanagementinsert.component";
import { ProjectmanagementdocumentlistComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentlist/projectmanagementdocumentlist.component";
import { ProjectmanagementdocumentdetailComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentdetail/projectmanagementdocumentdetail.component";
import { ProjectmanagementlistComponent } from "./components/brinspro/projectmanagement/projectmanagementlist/projectmanagementlist.component";
import { ProjectmanagementdocumentinsertComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentinsert/projectmanagementdocumentinsert.component";
import { ProjectmanagementdashboardComponent } from "./components/brinspro/projectmanagementdashboard/projectmanagementdashboard.component";
import { BarchartIncludeComponent } from "./components/includes/barchart.include/barchart.include.component";
import { GroupbarchartIncludeComponent } from "./components/includes/groupbarchart.include/groupbarchart.include.component";
import { PiechartIncludeComponent } from "./components/includes/piechart.include/piechart.include.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();
const maskConfig: Partial<IConfig> = { validation: false };


@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			DashboardComponent,
			TableControlIncludeComponent,
			TicketlistinboxComponent,
			TicketlistoutboxComponent,
			TicketdetailComponent,
			TicketSatisfactionComponent,
			TicketReportComponent,
			TicketReportSatisfactionComponent,
			DownloadComponent,
			FinancialhighlightComponent,
			RatiohighlightComponent,
			CapitalexpenditureComponent,
			OperationexpenditureComponent,
			OutstandingpremiComponent,
			InvestationComponent,
			ApprovalReimbursementListComponent,
			DentalreimbursementdetailComponent,
			OpticreimbursementdetailComponent,
			PostmaternityreimbursementdetailComponent,
			PrematernityreimbursementdetailComponent,
			MaternityreimbursementdetailComponent,
			TotalassetlistComponent,
			TotalassetdetailComponent,
			CapitalexpenditurelistComponent,
			CapitalexpendituredetailComponent,
			RiskbasedcapitallistComponent,
			RiskbasedcapitaldetailComponent,
			IndustrylistComponent,
			IndustrydetailComponent,
			FinancialhighlightdetailComponent,
			FinancialhighlightlistComponent,
			CapitalexpendituresworkplanbudgetlistComponent,
			CapitalexpendituresworkplanbudgetdetailComponent,
			OperationexpenditureworkplanbudgetlistComponent,
			OperationexpenditureworkplanbudgetdetailComponent,
			ProductionpercobComponent,
			ProductiongroupunitComponent,
			ProductionpersegmentComponent,
			MatrixsegmentationComponent,
			SupervisionbranchworkplanbudgetlistComponent,
			SupervisionbranchworkplanbudgetdetailComponent,
			PerformancesegmentworkplanbudgetlistComponent,
			PerformancesegmentworkplanbudgetdetailComponent,
			TotalsuminsuredComponent,
			YearMonthFormatDirective,
			FeebasedincomeworkplanbudgetlistComponent,
			FeebasedincomeworkplanbudgetdetailComponent,
			SegmentationportfoliopillarworkplanbudgetlistComponent,
			SegmentationportfoliopillarworkplanbudgetdetailComponent,
			SegmentationportfoliopillarworkplanbudgetlistComponent,
			SegmentationportfoliopillarworkplanbudgetdetailComponent,
			UncommitedcreditlineplafondlistComponent,
			UncommitedcreditlineplafonddetailComponent,
			FeebasedincomeComponent,
			PillarportfoliosegmentworkplanbudgetlistComponent,
			PillarportfoliosegmentworkplanbudgetdetailComponent,
			SyariahportfoliosegmentworkplanbudgetlistComponent,
			SyariahportfoliosegmentworkplanbudgetdetailComponent,
			DigitalportfoliosegmentworkplanbudgetlistComponent,
			DigitalportfoliosegmentworkplanbudgetdetailComponent,
			CaptiveportfoliosegmentworkplanbudgetlistComponent,
			CaptiveportfoliosegmentworkplanbudgetdetailComponent,
			AssessmentlistComponent,
			AssessmentdetailComponent,
			ApprovalrcsalistComponent,
			ApprovalrcsadetailComponent,
			ReportdetailComponent,
			ReportrcsalistComponent,
			InvestmentworkplanbudgetlistComponent,
			InvestmentworkplanbudgetdetailComponent,
			RiskissuerequestlistComponent,
			RiskissuerequestdetailComponent,
			SubmissionInsertComponent,
			SubmissionListComponent,
			SubmissionDetailComponent,
			CheckDevToolsDirective,
			DigitaldashboardComponent,
			EmateraicheckersignerlistComponent,
			EmateraicheckersignerdetailComponent,
			EmateraimakerlistComponent,
			EmateraimakerdetailComponent,
			BrinsEditorialListComponent,
			BrinsEditorialDetailComponent,
			ProfilegcgonlineComponent,
			GratificationlistComponent,
			GratificationdetailComponent,
			IntegritypactlistComponent,
			IntegritypactinsertComponent,
			IntegritypactdetailComponent,
			CodeofethicslistComponent,
			CodeofethicsinsertComponent,
			CodeofethicsdetailComponent,
			CodeofethicscheckersignerlistComponent,
			CodeofethicscheckersignerdetailComponent,
			AnnualdisclosurelistComponent,
			AnnualdisclosureinsertComponent,
			AnnualdisclosuredetailComponent,
			AnnualdisclosurecheckersignerlistComponent,
			AnnualdisclosurecheckersignerdetailComponent,
			CustomerdataComponent,
			ProjectmanagementlistComponent,
			ProjectmanagementdetailComponent,
			ProjectmanagementinsertComponent,
			ProjectmanagementdocumentlistComponent,
			ProjectmanagementdocumentdetailComponent,
			ProjectmanagementdocumentinsertComponent,
			ProjectmanagementdashboardComponent,
			BarchartIncludeComponent,
			GroupbarchartIncludeComponent,
			PiechartIncludeComponent
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			NgxChartsModule,
			MatNativeDateModule,
			MatInputModule,
			MatDatepickerModule,
			NgSelectModule,
			NgxMaskDirective,
			NgxMaskPipe,
			PdfViewerModule,
			DragDropModule,
			NgxEchartsModule.forRoot
			(
				{
					echarts: () => import("echarts")
				}
			),
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: environment.serviceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			}),
		],
		providers:
		[
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true,
			},
			provideEnvironmentNgxMask(maskConfig)
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion