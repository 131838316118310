//#region IMPORT

import { ENUM_HTTP_STATUS, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { ResponseCodeConstant } from "../constants/responsecode.constant";
import { GeneralModel } from "./bases/general.model";

//endregion


//region CLASS

export class ResponseModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for response model.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 Decemeber 2020.			    Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						    Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	HTTPResponseCode?: ENUM_HTTP_STATUS;
	ServiceResponseCode?: string;
	MessageTitle?: string;
	MessageContent?: string;
	Data?: string;
	State?: ENUM_RESPONSE_STATE;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.State = ENUM_RESPONSE_STATE.Nothing;
	}

	//#endregion


	//#region SETTER

	setServiceTooLarge(): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = ResponseCodeConstant.STRING_RESPONSECODE_CASE_GENERAL_SERVICETOOLARGE;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setSessionExpired(): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = ResponseCodeConstant.STRING_RESPONSECODE_CASE_GENERAL_SESSIONEXPIRED;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setWebsiteServiceException(stringMessageError: string): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = stringMessageError;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForValidation(stringMessageTitle: string): void
	{
		this.MessageTitle = stringMessageTitle;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForInsertMasterKeyPerformance(): void
	{
		this.MessageTitle = "Master Data";
		this.MessageContent = "Insert Master Data Berhasil";
	}

	setForUpdateMasterKeyPerformance(): void
	{
		this.MessageTitle = "Master Data";
		this.MessageContent = "Update Master Data Berhasil";
	}

	setForInsertKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Insert Key Performance Success";
	}

	setForUpdateKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Update Key Performance Success";
	}

	setForSubmitKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Submit Key Performance Success";
	}

	setForApproveKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Approval Key Performance Success";
	}

	setForRejectKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Reject Key Performance Success";
	}

	setForInsertProjectManagement(): void
	{
		this.MessageTitle = "Project Management";
		this.MessageContent = "Submit Project Management Success";
	}

	setForUpdateProjectManagement(): void
	{
		this.MessageTitle = "Project Management";
		this.MessageContent = "Update Project Management Success";
	}

	setForApproveProjectManagement(): void
	{
		this.MessageTitle = "Project Management";
		this.MessageContent = "Approval Project Management Success";
	}

	setForRejectProjectManagement(): void
	{
		this.MessageTitle = "Project Management";
		this.MessageContent = "Revise Project Management Success";
	}

	setForInsertProjectManagementDocument(): void
	{
		this.MessageTitle = "Project Management Document";
		this.MessageContent = "Submit Project Management Document Success";
	}

	//#endregion
}

//#endregion