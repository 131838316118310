//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StrategicAndPlanningModel } from "src/app/models/strategicandplanning.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { PwaService } from "src/app/services/pwa.service";
import { SessionService } from "src/app/services/session.service";
import { StrategicandplanningService } from "src/app/services/strategicandplanning.service";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { ENUM_LETTER_ROLE, ENUM_USERGROUPID } from "../../constants/enum.constant";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;
	public _booleanNavigationItem2: boolean | null;
	public _booleanNavigationItem3: boolean | null;
	public _booleanNavigationItem4: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationReimbursement: boolean | null;
	public _booleanNavigationApproval: boolean | null;
	public _booleanNavigationItemRCSA: boolean | null;
	public _booleanNavigationLegal: boolean | null;
	public _booleanNavigationItemDashboard: boolean | null;
	public _booleanNavigationItemGCGOnline: boolean | null;
	public _booleanNavigationCodeOfEthics: boolean | null;
	public _booleanNavigationAnnualDisclosure: boolean | null;
	public _booleanNavigationApuPPT: boolean | null;
	public _booleanNavigationBrinsprime: boolean | null;
	public _booleanNavigationKeyPerformanceBranch: boolean | null;
	public _booleanNavigationKeyPerformanceCompany: boolean | null;
	public _booleanNavigationKeyPerformanceDirector: boolean | null;
	public _booleanNavigationKeyPerformanceCorporateSubmission: boolean | null;
	public _booleanNavigationKeyPerformanceDirectorSubmission: boolean | null;
	public _booleanNavigationBrinspro: boolean | null;

	public _enumLetterRole = ENUM_LETTER_ROLE;
	public _enumUserGroupAccess = ENUM_USERGROUPID;

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceStrategicAndPlanning: StrategicandplanningService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationItem2 = null;
		this._booleanNavigationItem3 = null;
		this._booleanNavigationItem4 = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationReimbursement = null;
		this._booleanNavigationApproval = null;
		this._booleanNavigationItemRCSA = null;
		this._booleanNavigationLegal = null;
		this._booleanNavigationItemDashboard = null;
		this._booleanNavigationItemGCGOnline = null;
		this._booleanNavigationCodeOfEthics = null;
		this._booleanNavigationAnnualDisclosure = null;
		this._booleanNavigationApuPPT = null;
		this._booleanNavigationBrinsprime = null;
		this._booleanNavigationKeyPerformanceBranch = null;
		this._booleanNavigationKeyPerformanceCompany = null;
		this._booleanNavigationKeyPerformanceDirector = null;
		this._booleanNavigationKeyPerformanceCorporateSubmission = null;
		this._booleanNavigationKeyPerformanceDirectorSubmission = null;
		this._booleanNavigationBrinspro = null;
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
	}

	//#endregion


	//#region FUNCTION

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationItemGCGOnline = false;
		this._booleanNavigationCodeOfEthics = false;
		this._booleanNavigationAnnualDisclosure = false;
		this._booleanNavigationApuPPT = false;
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();
		this._functionUserInterface.setLoadingProgress(2);

		if (modelUserSession != null)
		{
			componentCurrent._functionUserInterface.updateLoadingProgress();
			this._serviceStrategicAndPlanning.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new StrategicAndPlanningModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	//#endregion


	//#region NAVIGATION

	goToUtilityDashboard(): void
	{
		this._router.navigate(["home", "dashboard", "utilitydashboard"]);
		this.toggleNavigation();
	}

	goToDigitalDashboard(): void
	{
		this._router.navigate(["home", "dashboard", "digitaldashboard"]);
		this.toggleNavigation();
	}

	goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
		this.toggleNavigation();
	}

	goToTicketReceived(): void
	{
		this._router.navigate(["home", "ticket", "received", "list"]);
		this.toggleNavigation();
	}

	goToTicketSent(): void
	{
		this._router.navigate(["home", "ticket", "sent", "list"]);
		this.toggleNavigation();
	}

	goToTicketReport(): void
	{
		this._router.navigate(["home", "ticket", "report"]);
		this.toggleNavigation();
	}

	goToTicketSatisfactionReport(): void
	{
		this._router.navigate(["home", "ticket", "satisfactionreport"]);
		this.toggleNavigation();
	}

	goToEmaterai(): void
	{
		this._router.navigate(["home", "ematerai", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForMaker(): void
	{
		this._router.navigate(["home", "ematerai", "maker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForChecker(): void
	{
		this._router.navigate(["home", "ematerai", "checker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForSigner(): void
	{
		this._router.navigate(["home", "ematerai", "signer", "list"]);
		this.toggleNavigation();
	}

	goToFinancialHighlight(): void
	{
		this._router.navigate(["home", "corporateperformance", "financialhighlight"]);
		this.toggleNavigation();
	}

	goToRatioHighlight(): void
	{
		this._router.navigate(["home", "corporateperformance", "ratiohighlight"]);
		this.toggleNavigation();
	}

	goToCapitalExpenditure(): void
	{
		this._router.navigate(["home", "corporateperformance", "capitalexpenditure"]);
		this.toggleNavigation();
	}

	goToOperationExpenditure(): void
	{
		this._router.navigate(["home", "corporateperformance", "operationexpenditure"]);
		this.toggleNavigation();
	}

	goToOutstandingPremi(): void
	{
		this._router.navigate(["home", "corporateperformance", "outstandingpremi"]);
		this.toggleNavigation();
	}

	goToInvestation(): void
	{
		this._router.navigate(["home", "corporateperformance", "investation"]);
		this.toggleNavigation();
	}

	goToProductionPerCOB(): void
	{
		this._router.navigate(["home", "corporateperformance", "productionpercob"]);
		this.toggleNavigation();
	}

	goToProductionPerBranch(): void
	{
		this._router.navigate(["home", "corporateperformance", "productionperbranch"]);
		this.toggleNavigation();
	}

	goToProductionGroupUnit(): void
	{
		this._router.navigate(["home", "corporateperformance", "productiongroupunit"]);
		this.toggleNavigation();
	}
	goToProductionFeeBasedIncome(): void
	{
		this._router.navigate(["home", "corporateperformance", "feebasedincome"]);
		this.toggleNavigation();
	}

	goToProductionPerSegment(): void
	{
		this._router.navigate(["home", "corporateperformance", "productionpersegment"]);
		this.toggleNavigation();
	}

	goToMatrixSegment(): void
	{
		this._router.navigate(["home", "corporateperformance", "matrixsegement"]);
		this.toggleNavigation();
	}

	goToTotalSumInsured(): void
	{
		this._router.navigate(["home", "corporateperformance", "totalsuminsured"]);
		this.toggleNavigation();
	}

	goToRKA(): void
	{
		this._router.navigate(["home", "workplanbudget", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralChecker(): void
	{
		this._router.navigate(["home", "reimbursement" ,"approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralSigner(): void
	{
		this._router.navigate(["home", "reimbursement", "approval", "signer", "list"]);
	}

	goToAsset(): void
	{
		this._router.navigate(["home", "totalasset", "list"]);
		this.toggleNavigation();
	}

	goToAssessment(): void
	{
		this._router.navigate(["home", "rcsa", "assessment", "list"]);
		this.toggleNavigation();
	}

	goToApprovalRCSA(): void
	{
		this._router.navigate(["home", "rcsa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToReportRCSA(): void
	{
		this._router.navigate(["home", "rcsa", "report", "list"]);
		this.toggleNavigation();
	}

	goToRiskIssueRequest(): void
	{
		this._router.navigate(["home", "rcsa", "riskissuerequest", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationChecker(): void
	{
		this._router.navigate(["home", "digitalization", "checker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationMaker(): void
	{
		this._router.navigate(["home", "digitalization", "maker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationSigner(): void
	{
		this._router.navigate(["home", "digitalization", "signer", "list"]);
		this.toggleNavigation();
	}

	goToBRINSEditorial(): void
	{
		this._router.navigate(["home", "brinseditorial", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineProfile(): void
	{
		this._router.navigate(["home", "gcgonline", "profile"]);
		this.toggleNavigation();
	}

	goToGCGOnlineGratification(): void
	{
		this._router.navigate(["home", "gcgonline", "gratification", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineIntegrityPact(): void
	{
		this._router.navigate(["home", "gcgonline", "integritypact", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthics(): void
	{
		this._router.navigate(["home", "gcgonline", "codeofethics", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthicsApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToGCGOnlineAnnualDisclosure(): void
	{
		this._router.navigate(["home", "gcgonline", "annualdisclosure", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineAnnualDisclosureApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToAPUPPTCustomer(): void
	{
		this._router.navigate(["home", "apu-ppt", "customerdata"]);
	}

	goToMasterKeyPerformanceList(): void
	{
		this._router.navigate(["home", "brinsprime", "branch", "master", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceMonitoring(): void
	{
		this._router.navigate(["home", "brinsprime", "branch", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToMasterKeyPerformanceCorporateList(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "master", "list"]);
		this.toggleNavigation();
	}

	goToMasterKeyPerformanceCorporateMonitoring(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "master", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceApprovalCompany(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "approval"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceCorporateReporting(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "monitoring"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceCorporateSubmission(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "submission", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceCorporateSubmited(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "submited", "list"]);
		this.toggleNavigation();
	}

	goToMasterKeyPerformanceDirector(): void
	{
		this._router.navigate(["home", "brinsprime", "director", "master", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceDirectorApproval(): void
	{
		this._router.navigate(["home", "brinsprime", "director", "approval"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceDirectorReporting(): void
	{
		this._router.navigate(["home", "brinsprime", "director", "reporting"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceDirectorSubmission(): void
	{
		this._router.navigate(["home", "brinsprime", "director", "submission", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceDirectorSubmited(): void
	{
		this._router.navigate(["home", "brinsprime", "director", "submited", "list"]);
		this.toggleNavigation();
	}

	goProjectManagementDashboard(): void
	{
		this._router.navigate(["home", "brinspro", "dashboard"]);
		this.toggleNavigation();
	}

	goToProjectManagementProject(): void
	{
		this._router.navigate(["home", "brinspro", "project", "list"]);
		this.toggleNavigation();
	}

	goToProjectManagementDocument(): void
	{
		this._router.navigate(["home", "brinspro", "document", "list"]);
		this.toggleNavigation();
	}

	//#endregion


}

//#endregion